<template>
  <div class="bg-v3-purple rounded-full h-fit bg-opacity-20">
      <loading v-if="!links"></loading>
      <template v-else>
    <div class="h-full p-2">
      <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
        <ul role="list" class="space-y-1 flex flex-col justify-between h-full">
          <div>
            <navigation-link name="Profile" v-if="hasLink('current-user')" :is-active="isCurrentPage('/users')" :open="panelOpen" :href="getLinkHref('current-user')" activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
              <template v-slot:icon>
                  <svg  class="h-6 w-6 shrink-0 fill-current" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 12C12.7091 12 14.5 10.2091 14.5 8C14.5 5.79086 12.7091 4 10.5 4C8.29086 4 6.5 5.79086 6.5 8C6.5 10.2091 8.29086 12 10.5 12Z" />
                      <path d="M11.17 13.02C10.95 13.01 10.73 13 10.5 13C8.08 13 5.82 13.67 3.89 14.82C3.01 15.34 2.5 16.32 2.5 17.35V20H11.76C11.0537 18.9924 10.627 17.8157 10.5234 16.5896C10.4197 15.3635 10.6428 14.1318 11.17 13.02ZM21.25 16C21.25 15.78 21.22 15.58 21.19 15.37L22.33 14.36L21.33 12.63L19.88 13.12C19.56 12.85 19.2 12.64 18.8 12.49L18.5 11H16.5L16.2 12.49C15.8 12.64 15.44 12.85 15.12 13.12L13.67 12.63L12.67 14.36L13.81 15.37C13.78 15.58 13.75 15.78 13.75 16C13.75 16.22 13.78 16.42 13.81 16.63L12.67 17.64L13.67 19.37L15.12 18.88C15.44 19.15 15.8 19.36 16.2 19.51L16.5 21H18.5L18.8 19.51C19.2 19.36 19.56 19.15 19.88 18.88L21.33 19.37L22.33 17.64L21.19 16.63C21.22 16.42 21.25 16.22 21.25 16ZM17.5 18C16.4 18 15.5 17.1 15.5 16C15.5 14.9 16.4 14 17.5 14C18.6 14 19.5 14.9 19.5 16C19.5 17.1 18.6 18 17.5 18Z"/>
                  </svg>

              </template>
            </navigation-link>
          </div>
          <div class="pt-12">
            <navigation-link name="My Dash" :open="panelOpen" href="/"  activeClass="text-v3-purple"  additional-classes="text-v3-purple ring-2 ring-current">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
          </div>
        </ul>
      </nav>
    </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'profile-navigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: false
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    mounted(){
      const storageSetting = window.localStorage.getItem('navigationPanelOpen');
      if(storageSetting!== null){
          this.panelOpen = storageSetting === "true";
      }
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path.includes(url)
      },
        togglePanel(){
          this.panelOpen = !this.panelOpen;
          window.localStorage.setItem('navigationPanelOpen', this.panelOpen);
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
    },

}
</script>
