<template>
    <main-template >
        <template v-slot:headerLottieDark>
            <dotlottie-player :src="getDarkLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:headerLottieLight>
            <dotlottie-player :src="getLightLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:header>
            <template v-if="!isCurrentUser">Base</template>
            <template v-else>Profile</template>
        </template>
        <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link" @responseLoaded="loadDetails">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full" v-if="!loading">
                            <Hr-navigation v-if="!isCurrentUser" :links="slotProps.links"/>
                            <profile-navigation v-else :links="slotProps.links"/>
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                <loading v-if="!response"></loading>
                                <template v-else>
                                    <hr-person-single class="p-2" ref="singleDetails" v-if="userDetails" :userDetails="userDetails" :editing="editing" :update-action="updateAction" @saveClicked="saveClicked" :hasTable="false" activeClass="bg-v3-purple bg-opacity-20 dark:bg-v3-purple dark:bg-opacity-20" @reloadDetails="reloadDetails"></hr-person-single>
                                </template>
                            </div>
                        </div>
                    </div>


                </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>

import FetchSirenRoot from "@/components/FetchSirenRoot.vue";
import fileDownload from "js-file-download";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import HrPersonSingle from "@/v3/components/HrPersonSingle.vue";
import Loading from "@/components/Loading.vue";
import MainTemplate from "@/v3/templates/MainTemplate.vue";
import {mapGetters} from "vuex";
import ProfileNavigation from "@/v3/components/navigation/ProfileNavigation.vue";

export default {
    components: {HrNavigation, HrPersonSingle, MainTemplate, Loading, FetchSirenRoot, ProfileNavigation},
    inject: ["$validator"],
    data(){
        return {
            userDetails: null,
            editing: false,
            isCurrentUser: false,
            loading: true
        }
    },
    computed:{
        ...mapGetters({
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        updateAction() {
            if (!this.userDetails) {
                return null;
            }
            return this.userDetails.actions.filter(action => action.name === 'update-user').first()
        },
    },
    methods:{
        loadDetails(response){
            this.userDetails = response.body;
            if(this.userDetails && this.userDetails.properties.get('id') === this.user['id'] ){
                this.isCurrentUser = true;
            }
            this.loading = false;
        },
        saveClicked() {
            if(!this.submitting) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submitting = true;
                this.updateAction.perform(this.$refs.singleDetails.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submitting = false;
                        return;
                    }
                    this.$refs.singleDetails.editing = false;
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.userDetails = res.body;
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        },
        reloadDetails(){
            this.$refs.sirenRoot.load();
        }
    }
}
</script>

